import React from 'react';
import { shape, string } from 'prop-types';
import { history } from 'byebye';
import AltContainer from 'alt-container';
import { getCountryCode } from 'instances/i18n';
import AuthStore from 'stores/AuthStore';
import SearchStore from 'stores/SearchStore';
import TilesStore from 'stores/TilesStore';
import SearchActions from 'actions/SearchActions';
import { getTimelineTiles } from 'selectors/tiles';
import { STATUS_OK } from 'app-constants';
import SearchPage from '../components/SearchPage';

class MicropaymentSearchContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { params } = this.props;

    if (params.splat) {
      this.fetchResults({ keyword: params.splat });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params } = this.props;

    if (params.splat !== nextProps.params.splat) {
      this.fetchResults({ keyword: nextProps.params.splat });
    }
  }

  fetchResults = (query) => {
    if (!query.keyword) {
      return;
    }

    const userId = AuthStore.getState().user.id;
    const locale = query.locale ? query.locale : getCountryCode().toLowerCase();

    history.navigate(`search/${query.keyword}`, {
      replace: true,
      trigger: false,
    });
    SearchActions.fetchResults(query.keyword, locale, userId, query.date);
  };

  onFetchNextResults = () => {
    const { status, next } = SearchStore.getState();

    if (status === STATUS_OK && next) {
      SearchActions.fetchNextResults(next);
    }
  };

  renderSearch = ({ searchState, tilesState }) => (
    <SearchPage
      status={searchState.status}
      query={searchState.query}
      results={{
        items: getTimelineTiles(tilesState.tiles, searchState.itemIds),
        issues: searchState.issues,
      }}
      onFetchNextResults={this.onFetchNextResults}
      onSearch={this.fetchResults}
      active={searchState.active}
    />
  );

  render() {
    return (
      <AltContainer
        stores={{
          searchState: SearchStore,
          tilesState: TilesStore,
        }}
        render={this.renderSearch}
      />
    );
  }
}

MicropaymentSearchContainer.propTypes = {
  params: shape({
    splat: string,
  }).isRequired,
};

export default MicropaymentSearchContainer;
