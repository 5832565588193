// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-TECM6{from{opacity:0}to{opacity:1}}.title-1jWkt{font-family:\"GT-Walsheim\",sans-serif;font-weight:600;font-size:18px;line-height:21px;letter-spacing:-0.03em;color:#000;animation:fadeIn-TECM6 0.2s 0s ease-in forwards}@media (min-width: 600px){.title-1jWkt{font-size:22px;line-height:26px}}.body-39kVj{font-family:\"Inter\",sans-serif;font-size:14px;line-height:17px;color:#5b5b5b;animation:fadeIn-TECM6 0.2s 0s ease-in forwards}@media (min-width: 600px){.body-39kVj{font-size:16px;line-height:20px}}\n", ""]);
// Exports
exports.locals = {
	"title": "title-1jWkt",
	"fadeIn": "fadeIn-TECM6",
	"body": "body-39kVj"
};
module.exports = exports;
