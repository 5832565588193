import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function getTimeAgo(date) {
  const startOfDay = moment().startOf('day');
  const publishDay = moment(date).startOf('day');
  const publshedToday = startOfDay.diff(publishDay) === 0;
  return publshedToday ? startOfDay.calendar() : publishDay.calendar();
}

function SearchContextToast(props) {
  const { term, snippet, date } = props;

  return (
    <div className="v-toast v-results-toast">
      <div className="icon-search" />
      <div className="title">{term}</div>
      <div className="timeago">{getTimeAgo(date)}</div>
      <div className="snippet" dangerouslySetInnerHTML={{ __html: snippet }} />
    </div>
  );
}

SearchContextToast.propTypes = {
  term: PropTypes.string.isRequired,
  snippet: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default SearchContextToast;
