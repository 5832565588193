import React, { useEffect } from 'react';
import styledModule from 'helpers/styledModule';
import { node } from 'prop-types';
import SearchStore from 'stores/SearchStore';
import useAlt from 'hooks/useAlt';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);

const SearchPage = ({ children }) => {
  const { active, scrollPosition } = useAlt([SearchStore], ([searchState]) => searchState);

  useEffect(() => {
    if (active) {
      window.scrollTo(0, scrollPosition);
    }
  }, [active, scrollPosition]);

  return <Container className={active ? '' : CSS.hidden}>{children}</Container>;
};

SearchPage.propTypes = {
  children: node.isRequired,
};

export default SearchPage;
