import React, { useEffect, memo, useState } from 'react';
import { string } from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { IS_NETWORK_STATUS } from 'selectors/graphQL';
import {
  filter,
  path,
  pathEq,
  pipe,
  pluck,
  prop,
  anyPass,
  isEmpty,
  isNil,
  trim,
  pathOr,
} from 'ramda';
import Analytics from 'instances/analytics';
import useHasCreditsCapability from 'hooks/useHasCreditsCapability';
import resetStyles from 'higher-order-components/resetStyles';
import ProviderCardWrapper from 'components/ProviderCardWrapper';
import { INTERNAL_LOCATION, GRAPHQL_TYPE_NAME } from 'app-constants';
import ArticleGrid from 'components/ArticleGrid';
import Loader from 'components/Loader';
import InfiniteScroll from 'react-infinite-scroller';
import SegmentAnalytics from 'instances/segmentAnalytics';
import { SEARCH_QUERY } from '../queries';
import SearchPage from '../components/SearchPage';
import NoResults from '../components/NoResults';
import ResultTitle from '../components/ResultTitle';
import InitialPage from '../components/InitialPage';

const internalLocation = INTERNAL_LOCATION.DISCOVER_SEARCH;

const pageInfo = path(['searchPage', 'pageInfo']);
const endCursor = pipe(pageInfo, prop('endCursor'));
const hasNextPage = pipe(pageInfo, prop('hasNextPage'));
const edges = pathOr([], ['searchPage', 'edges']);

const getSearchArticleCardNodes = pipe(
  edges,
  filter(pathEq(['node', '__typename'], GRAPHQL_TYPE_NAME.SEARCH_ARTICLE_CARD)),
  pluck('node'),
);

const isEmptyQuery = anyPass([isEmpty, isNil]);

const updateQuery = (previousResults, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResults;
  }

  return {
    searchPage: {
      ...previousResults.searchPage,
      pageInfo: pageInfo(fetchMoreResult),
      edges: [...edges(previousResults), ...edges(fetchMoreResult)],
    },
  };
};

const PremiumSearchContainer = memo(({ searchQuery }) => {
  const [query, setQuery] = useState();
  const hasCreditsCapability = useHasCreditsCapability();

  const [runSearchQuery, { error, data, fetchMore, networkStatus }] = useLazyQuery(SEARCH_QUERY, {
    variables: {
      credit: hasCreditsCapability,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    throw error;
  }

  const isReady = IS_NETWORK_STATUS.ready(networkStatus);
  const showInitialLoadingState = IS_NETWORK_STATUS.initialLoading(networkStatus);

  const searchArticleCards = data ? getSearchArticleCardNodes(data) : null;

  const numberOfSearchResults = searchArticleCards ? searchArticleCards.length : undefined;

  const showNoResultsState = isReady && numberOfSearchResults === 0;

  const showInitialState = !data && isReady;

  const sendResultsShownEvent = () => {
    const properties = {
      query: query.toLowerCase(),
      internal_location: internalLocation,
      results:
        searchArticleCards && searchArticleCards.map(({ article }) => `item:${article.bnlId}`),
    };

    Analytics.track('Search Results Shown', properties);
    SegmentAnalytics.page({ pageName: 'Search Results', properties });
  };

  const executeSearch = (queryToSubmit) => {
    Analytics.track('Search', {
      query: queryToSubmit.toLowerCase(),
      internal_location: internalLocation,
    });

    runSearchQuery({
      variables: {
        query: queryToSubmit,
      },
    });
  };

  const onFetchMore = () => {
    if (isReady) {
      fetchMore({
        variables: {
          after: endCursor(data),
        },
        updateQuery,
      });
    }
  };

  // Take the query that comes in via the props and put in the local state if it's a valid query
  useEffect(() => {
    const trimmedQuery = searchQuery && trim(searchQuery);

    if (!isEmptyQuery(trimmedQuery) && trimmedQuery !== query) {
      setQuery(trimmedQuery);
    }
  }, [searchQuery]);

  // Run the search when there is a query to search
  useEffect(() => {
    if (query) {
      executeSearch(query);
    }
  }, [query]);

  // Send analytics event when there are search results and when more results are fetched
  useEffect(() => {
    if (numberOfSearchResults >= 0) {
      sendResultsShownEvent();
    }
  }, [numberOfSearchResults]);

  const renderContent = () => {
    if (numberOfSearchResults > 0) {
      return (
        <>
          <ResultTitle searchQuery={query} />
          <InfiniteScroll
            loadMore={onFetchMore}
            initialLoad={false}
            hasMore={hasNextPage(data)}
            loader={<Loader key="load-more-indicator" />}
            data-testid="infinite-scroll"
          >
            <ArticleGrid key="article-grid" data-testid="result-wrapper">
              {searchArticleCards.map((item) => (
                <ProviderCardWrapper
                  key={item.article.id}
                  analytics={{
                    internal_location: internalLocation,
                    query: query.toLowerCase(),
                  }}
                  {...item.article}
                />
              ))}
            </ArticleGrid>
          </InfiniteScroll>
        </>
      );
    }

    if (showInitialLoadingState) {
      return <Loader />;
    }

    if (showInitialState) {
      return <InitialPage data-testid="initial-page" />;
    }

    if (showNoResultsState) {
      return <NoResults data-testid="no-result" />;
    }

    return null;
  };

  return <SearchPage>{renderContent()}</SearchPage>;
});

PremiumSearchContainer.propTypes = {
  searchQuery: string,
};

PremiumSearchContainer.defaultProps = {
  searchQuery: undefined,
};

export default resetStyles(PremiumSearchContainer);
