import { gql } from 'apollo-boost';
import { GRAPHQL_TYPE_NAME } from 'app-constants';

export const updateCache = ({ bnlId, cache, isPinned }) => {
  cache.writeFragment({
    id: `${GRAPHQL_TYPE_NAME.ARTICLE}:${bnlId}`,
    fragment: gql`
      fragment pinnedFragment on Article {
        viewerContext {
          pinned
        }
      }
    `,
    data: {
      __typename: GRAPHQL_TYPE_NAME.ARTICLE,
      viewerContext: {
        pinned: isPinned,
        __typename: GRAPHQL_TYPE_NAME.ARTICLE_VIEWER_CONTEXT,
      },
    },
  });
};
