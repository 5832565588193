import React, { memo, cloneElement } from 'react';
import { usePolyfilledMeasure } from 'hooks/usePolyfilledMeasure';
import { node } from 'prop-types';

const GridItem = memo(({ item }) => {
  const [measureRef, bounds] = usePolyfilledMeasure();
  const { width, height } = bounds;

  return (
    <div ref={measureRef}>
      {width > 0 && height > 0
        ? cloneElement(item, {
            width,
            height,
          })
        : null}
    </div>
  );
});

GridItem.propTypes = {
  item: node.isRequired,
};

export default GridItem;
