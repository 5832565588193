import React from 'react';
import Tile from 'components/Tile';
import { node, string, shape, number, func } from 'prop-types';
import { useMemoizedCoverTileStyles } from 'helpers/coverTile';
import Cover from '../Cover';

const CoverTile = ({ axis, availableSize, issue, children, numberOfItemsPerRowOnYAxis }) => {
  const coverWidth = issue.getCoverWidth();
  const coverHeight = issue.getCoverHeight();

  const style = useMemoizedCoverTileStyles({
    axis,
    availableSize,
    coverWidth,
    coverHeight,
    numberOfItemsPerRowOnYAxis,
  });

  return (
    <Tile type="cover">
      {children}
      <Cover issue={issue} coverStyles={style} />
    </Tile>
  );
};

CoverTile.propTypes = {
  /* AvailableSize and Axis props are injected by ScrollList */
  // eslint-disable-next-line react/require-default-props
  availableSize: shape({
    width: number.isRequired,
    height: number.isRequired,
  }),
  // eslint-disable-next-line react/require-default-props
  axis: string,
  issue: shape({
    getCoverWidth: func.isRequired,
    getCoverHeight: func.isRequired,
  }).isRequired,
  children: node,
  numberOfItemsPerRowOnYAxis: number.isRequired,
};

CoverTile.defaultProps = {
  children: undefined,
};

export default CoverTile;
