// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3FEya{background-color:#f2f2f6;padding-top:34px;padding-bottom:60px;height:100%;min-height:calc(100vh - 54px)}@media (min-width: 600px){.container-3FEya{min-height:calc(100vh - 66px)}}.hidden-iV3Te{display:none}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3FEya",
	"hidden": "hidden-iV3Te"
};
module.exports = exports;
