import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ManifestContainer from 'containers/ManifestContainer';
import SearchContextToastContainer from '../containers/SearchContextToastContainer';

export default class ResultItemTile extends PureComponent {
  static propTypes = {
    itemId: PropTypes.string.isRequired,
    analytics: PropTypes.object.isRequired,
  };

  render() {
    const { itemId, analytics } = this.props;

    return (
      <div className="item-tile-container item-strech-tile-container v-tile">
        <SearchContextToastContainer itemId={itemId} />
        <ManifestContainer itemId={itemId} analytics={analytics} />
      </div>
    );
  }
}
