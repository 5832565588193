import React from 'react';
import PropTypes from 'prop-types';
import Tile from 'components/Tile';
import { translateElement } from 'instances/i18n';

export default function NoResultsTile({ query }) {
  return (
    <Tile type="message">
      <div className="error-body">{translateElement('search.errors.no_results', [query])}</div>
    </Tile>
  );
}

NoResultsTile.propTypes = {
  query: PropTypes.string.isRequired,
};
