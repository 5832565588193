import { gql } from 'apollo-boost';

export const ADD_ARTICLE_TO_READING_LIST = gql`
  mutation AddArticleToReadingList($bnlId: String!) {
    addArticleToReadingList(input: { bnlId: $bnlId }) {
      success
      errors {
        message
        path
      }
    }
  }
`;

export const REMOVE_ARTICLE_FROM_READING_LIST = gql`
  mutation RemoveArticleFromReadingList($bnlId: String!) {
    removeArticleFromReadingList(input: { bnlId: $bnlId }) {
      success
      errors {
        message
        path
      }
    }
  }
`;
