// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".grid-1u9xg{width:100%;max-width:1120px;margin:0 auto;padding:0 10px;display:grid;grid-gap:20px;grid-template-columns:1fr}@media (min-width: 350px){.grid-1u9xg{padding:0 20px}}@media (min-width: 660px){.grid-1u9xg{grid-template-columns:1fr 1fr}}@media (min-width: 980px){.grid-1u9xg{grid-template-columns:1fr 1fr 1fr}}.grid-1u9xg>div{height:400px}\n", ""]);
// Exports
exports.locals = {
	"grid": "grid-1u9xg"
};
module.exports = exports;
