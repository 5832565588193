import React from 'react';
import styledModule from 'helpers/styledModule';
import { string } from 'prop-types';
import { translate } from 'instances/i18n';
import CSS from './styles.scss';
import SharedCSS from '../shared.scss';

const Container = styledModule.div(CSS.container);
const Title = styledModule.h1(SharedCSS.title, CSS.title);

const ResultTitle = ({ searchQuery }) => (
  <Container>
    <Title>{translate('search.premium_search.results.title', searchQuery)}</Title>
  </Container>
);

ResultTitle.propTypes = {
  searchQuery: string.isRequired,
};

export default ResultTitle;
