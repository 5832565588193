import {
  getMainTitle,
  getSubTitle,
  getParagraphs,
  getIntro,
  getLead,
  getParagraphHeader,
} from 'helpers/manifest';

/*
 * takes the required content elements for the ProviderCard from the contentPreviewElements or manifestBody
 */
export const getContentElements = (contentPreviewElements) => ({
  title: getMainTitle(contentPreviewElements),
  subtitle: getSubTitle(contentPreviewElements),
  intro: getIntro(contentPreviewElements) || getLead(contentPreviewElements),
  paragraphHeader: getParagraphHeader(contentPreviewElements),
  paragraphs: getParagraphs(contentPreviewElements),
});
