import React from 'react';
import styledModule from 'helpers/styledModule';
import { ChevronRightIcon } from '@blendle/lego';
import Link from 'components/Link';
import { translate, translateElement } from 'instances/i18n';
import CSS from './styles.scss';
import SharedCSS from '../shared.scss';

const Container = styledModule.div(CSS.container);
const ContentWrapper = styledModule.div(CSS.contentWrapper);
const Title = styledModule.h1(SharedCSS.title, CSS.title);
const Body = styledModule.p(SharedCSS.body);
const IconWrapper = styledModule.div(CSS.iconWrapper);
const StyledChevronRightIcon = styledModule(ChevronRightIcon)(CSS.icon);

const InitialPage = () => (
  <Container>
    <Link href="/earlier">
      <ContentWrapper>
        <div>
          <Title>{translate('search.premium_search.initial_state.title')}</Title>
          <Body>{translateElement('search.premium_search.initial_state.text')}</Body>
        </div>
        <IconWrapper>
          <StyledChevronRightIcon />
        </IconWrapper>
      </ContentWrapper>
    </Link>
  </Container>
);

export default InitialPage;
