// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1B90-{text-align:center;padding:60px 16px;max-width:324px;margin:0 auto}.title-23LOa{padding-bottom:12px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1B90-",
	"title": "title-23LOa"
};
module.exports = exports;
