// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-31gCk{width:100%;max-width:1120px;margin:0 auto;padding:0 10px}@media (min-width: 350px){.container-31gCk{padding:0 20px}}.title-374dL{padding-bottom:17px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-31gCk",
	"title": "title-374dL"
};
module.exports = exports;
