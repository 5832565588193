import React, { useState } from 'react';
import { string, shape, node, arrayOf } from 'prop-types';
import ItemDetails from 'components/ManifestDropdown/ItemDetails';
import DropdownSharingButtons from 'components/DropdownSharingButtons';
import ShareActions from 'actions/ShareActions';
import { getShareItemUrl } from 'helpers/shareItemUrl';
import useUser from 'hooks/useUser';
import { createItemUri } from 'helpers/prettyUrl';
import { LOCATION_IN_LAYOUT, SHARING_PLATFORM } from 'app-constants';
import ShareToEmailContainer from 'containers/ShareToEmailContainer';

const DropdownContent = ({
  readingTimeCopy,
  publishedAt,
  contentPreviewElements,
  providerName,
  bnlId,
  featuredImage,
  analytics: incomingAnalytics,
}) => {
  const user = useUser();
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const getUrl = (platform) =>
    getShareItemUrl({
      userId: user.id,
      path: createItemUri({ contentPreviewElements, providerName, bnlId }),
      bnlId,
      utmData: {
        utm_medium: platform,
        utm_campaign: 'social-share',
        utm_source: 'blendle',
      },
    });

  const analytics = {
    ...incomingAnalytics,
    location_in_layout: LOCATION_IN_LAYOUT.MANIFEST_DROPDOWN,
  };

  return (
    <div>
      <DropdownSharingButtons
        onShareToWhatsApp={(ev) => {
          ev.preventDefault();
          ShareActions.shareItemToWhatsApp({
            providerName,
            body: contentPreviewElements,
            url: getUrl(SHARING_PLATFORM.WHATSAPP),
            analytics,
          });
        }}
        onShareToEmail={(ev) => {
          ev.preventDefault();
          setIsEmailDialogOpen(true);
        }}
        onShareToFacebook={(ev) => {
          ev.preventDefault();
          ShareActions.shareItemToFacebook({
            providerName,
            body: contentPreviewElements,
            url: getUrl(SHARING_PLATFORM.FACEBOOK),
            analytics,
            image: featuredImage ? featuredImage.url : undefined,
            date: publishedAt,
          });
        }}
        onShareToTwitter={(ev) => {
          ev.preventDefault();
          ShareActions.shareItemToTwitter({
            providerName,
            body: contentPreviewElements,
            url: getUrl(SHARING_PLATFORM.TWITTER),
            analytics,
          });
        }}
      />
      <ShareToEmailContainer
        itemId={bnlId}
        analytics={analytics}
        isVisible={isEmailDialogOpen}
        onClose={() => setIsEmailDialogOpen(false)}
      />
      <ItemDetails readingTime={{ copy: readingTimeCopy }} date={publishedAt} />
    </div>
  );
};

DropdownContent.propTypes = {
  bnlId: string.isRequired,
  contentPreviewElements: arrayOf(shape({ content: string.isRequired, type: string.isRequired }))
    .isRequired,
  featuredImage: shape({
    credit: string,
    url: string.isRequired,
  }),
  providerName: string.isRequired,
  publishedAt: string.isRequired,
  readingTimeCopy: node.isRequired,
  analytics: shape({
    internal_location: string,
  }),
};

DropdownContent.defaultProps = {
  analytics: {},
  featuredImage: undefined,
};

export default DropdownContent;
