import React from 'react';
import styledModule from 'helpers/styledModule';
import { translate, translateElement } from 'instances/i18n';
import CSS from './styles.scss';
import SharedCSS from '../shared.scss';

const Container = styledModule.div(CSS.container);
const Title = styledModule.h1(SharedCSS.title, CSS.title);
const Body = styledModule.p(SharedCSS.body);

const NoResults = () => (
  <Container>
    <Title>{translate('search.premium_search.no_results.title')}</Title>
    <Body>{translateElement('search.premium_search.no_results.text')}</Body>
  </Container>
);

export default NoResults;
