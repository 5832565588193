import React, { memo } from 'react';
import { ProviderCard } from '@blendle/lego';
import moment from 'moment';
import { number, string, shape, bool, arrayOf } from 'prop-types';
import { pipe, prop, pluck } from 'ramda';

import { createItemUri } from 'helpers/prettyUrl';
import { getAudioDeeplinkLandingUrl } from 'helpers/audioDeeplinkUrl';
import { getContentElements } from 'helpers/providerCard';
import { LOCATION_IN_LAYOUT } from 'app-constants';
import { translate } from 'instances/i18n';
import Analytics from 'instances/analytics';
import BrowserEnv from 'instances/browser_environment';
import Link from 'components/Link';
import useReadingListMutations from 'hooks/useReadingListMutations';
import DropdownContent from './DropdownContent';

const SHOW_NUMBER_OF_LIKES_THRESHOLD = 10;
const NUMBER_OF_SECONDS_IN_A_MINUTE = 60;

const getEntitiesLabels = pipe(prop('nodes'), pluck('label'));

/*
 * Wrapper that takes in all the attributes from a GraphQL Article and renders a ProviderCard
 */
const ProviderCardWrapper = memo(
  ({
    id,
    bnlId,
    width,
    height,
    viewerContext,
    readingTime,
    audioTrack,
    contentPreviewElements,
    provider,
    featuredImage,
    analytics,
    publishedAt,
    entities,
  }) => {
    const { add: addToReadingList, remove: removeFromReadingList } = useReadingListMutations({
      bnlId,
      analytics,
    });

    const itemUrl = createItemUri({
      providerName: provider.name,
      bnlId,
      contentPreviewElements,
    });
    const readingTimeInMinutes = Math.ceil(readingTime / NUMBER_OF_SECONDS_IN_A_MINUTE);

    const { likeCount, pinned, opened, listened, formattedPrice } = viewerContext;
    const showNumberOfLikes = likeCount >= SHOW_NUMBER_OF_LIKES_THRESHOLD;

    const readLater = {
      isPinned: pinned,
      onClick: () => {
        if (pinned) {
          removeFromReadingList();
        } else {
          addToReadingList();
        }
      },
    };

    const audio = audioTrack && {
      buttonCopy: translate('app.manifest.listen'),
      onClick: () => {
        window.location.href = getAudioDeeplinkLandingUrl(audioTrack.uuid);
      },
    };

    const readingTimeCopy = translate('app.manifest.time.to.read_in_min', readingTimeInMinutes);

    const manifestDropdownContent = (
      <DropdownContent
        readingTimeCopy={readingTimeCopy}
        publishedAt={publishedAt}
        contentPreviewElements={contentPreviewElements}
        providerName={provider.name}
        bnlId={bnlId}
        featuredImage={featuredImage}
        analytics={analytics}
      />
    );

    const onManifestDropdownOpen = () => {
      Analytics.track('Open Manifest Dropdown', {
        ...analytics,
        item: bnlId,
        location_in_layout: LOCATION_IN_LAYOUT.MANIFEST_DROPDOWN,
      });
    };

    const image = featuredImage && {
      url: featuredImage.url,
      credits: featuredImage.credit,
      onError: (error) => {
        window.ErrorLogger.captureException(error);
      },
    };

    return width && height ? (
      <Link analytics={analytics} href={`/${itemUrl}`}>
        <ProviderCard
          itemId={bnlId}
          width={width}
          height={height}
          image={image}
          providerId={provider.providerId}
          readLater={readLater}
          isRead={opened || listened}
          {...getContentElements(contentPreviewElements)}
          numberOfLikes={likeCount}
          showNumberOfLikes={showNumberOfLikes}
          readingTimeCopy={readingTimeCopy}
          entities={getEntitiesLabels(entities)}
          audio={audio}
          manifestDropdownContent={manifestDropdownContent}
          onManifestDropdownOpen={onManifestDropdownOpen}
          disableHoverAnimation={BrowserEnv.hasTouch()}
          price={formattedPrice}
          publicationDate={moment(publishedAt).format('LL')}
        />
      </Link>
    ) : null;
  },
);

ProviderCardWrapper.propTypes = {
  id: string.isRequired,
  audioTrack: shape({
    uuid: string.isRequired,
  }),
  bnlId: string.isRequired,
  contentPreviewElements: arrayOf(shape({ content: string.isRequired, type: string.isRequired }))
    .isRequired,
  entities: shape({
    nodes: arrayOf(shape({ entityId: string.isRequired, label: string.isRequired })),
  }).isRequired,
  featuredImage: shape({
    credit: string,
    url: string.isRequired,
  }),
  provider: shape({
    name: string.isRequired,
    providerId: string.isRequired,
  }).isRequired,
  publishedAt: string.isRequired,
  readingTime: number.isRequired,
  viewerContext: shape({
    formattedPrice: string,
    likeCount: number.isRequired,
    liked: bool.isRequired,
    listened: bool.isRequired,
    opened: bool.isRequired,
    pinned: bool.isRequired,
  }).isRequired,
  width: number,
  height: number,
  analytics: shape({
    internal_location: string,
  }),
};

ProviderCardWrapper.defaultProps = {
  audioTrack: undefined,
  featuredImage: undefined,
  analytics: {},
  width: undefined,
  height: undefined,
};

export default ProviderCardWrapper;
