import { MAX_DISCOVER_TILE_HEIGHT } from 'app-constants';
import { useMemo } from 'react';

export const GUTTER_WIDTH = 16;
const MAX_ACCEPTABLE_ASPECT_RATIO = 1.5;

export const coverTileStyles = ({
  axis,
  availableSize,
  coverWidth,
  coverHeight,
  numberOfItemsPerRowOnYAxis,
}) => {
  if (axis === 'x') {
    const widthHeightRatio = coverWidth / coverHeight;
    const actualHeight = Math.min(MAX_DISCOVER_TILE_HEIGHT, availableSize.height);

    return {
      width: actualHeight * widthHeightRatio,
      height: actualHeight,
    };
  }

  if (axis === 'y') {
    const numberOfGutters = numberOfItemsPerRowOnYAxis + 1;

    const heightWidthRatio = Math.min(coverHeight / coverWidth, MAX_ACCEPTABLE_ASPECT_RATIO);
    const actualWidth =
      (availableSize.width - GUTTER_WIDTH * numberOfGutters) / numberOfItemsPerRowOnYAxis;

    return {
      width: actualWidth,
      height: actualWidth * heightWidthRatio,
      overflow: 'hidden',
    };
  }

  return null;
};

export const useMemoizedCoverTileStyles = (props) =>
  useMemo(() => coverTileStyles(props), [...props]);
