import React from 'react';
import { FEATURE_CAPABILITY_SEARCH } from 'app-constants';
import useAlt from 'hooks/useAlt';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { hasCapability } from 'selectors/capabilities';
import { withRouter } from 'react-router';
import { shape, string } from 'prop-types';
import { compose } from 'ramda';
import { withErrorBoundary } from 'higher-order-components/withErrorBoundary';
import MicropaymentSearchContainer from './micropaymentSearch/containers/MicropaymentSearchContainer';
import PremiumSearchContainer from './premiumSearch/containers/PremiumSearchContainer';

const SearchContainer = ({ params }) => {
  const featureCapabilitiesState = useAlt([FeatureCapabilitiesStore], ([state]) => state);

  const hasMicropaymentSearch = hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_SEARCH);

  if (hasMicropaymentSearch) {
    return <MicropaymentSearchContainer params={params} data-testid="mp-search" />;
  }

  return <PremiumSearchContainer searchQuery={params.splat} data-testid="premium-search" />;
};

SearchContainer.propTypes = {
  params: shape({
    splat: string,
  }).isRequired,
};

const enhance = compose(withErrorBoundary, withRouter);

export default enhance(SearchContainer);
