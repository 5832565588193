import React, { useState } from 'react';
import { shape, string, number, object, func } from 'prop-types';
import Analytics from 'instances/analytics';
import Image from 'components/Image';
import Button from 'components/Button';
import classNames from 'classnames';
import { providerById, prefillSelector } from 'selectors/providers';
import ProviderManager from 'managers/provider';
import BrowserEnvironment from 'instances/browser_environment';
import Link from 'components/Link';
import styledModule from 'helpers/styledModule';
import useUser from 'hooks/useUser';
import CSS from './styles.scss';

const ImageWrapper = styledModule.div(CSS.imageWrapper);

const Cover = ({ issue, onClick, onLoad, coverStyles }) => {
  const user = useUser();
  const [isReady, setIsReady] = useState(false);
  const [isFavorite, setTsFavorite] = useState(issue.get('favourite'));

  const onCoverLoad = (ev) => {
    setIsReady(true);

    if (onLoad) {
      onLoad(ev);
    }
  };

  const toggleFavourite = (e) => {
    e.preventDefault();

    const providerId = issue.get('provider').id;
    const statsPayload = {
      type: 'kiosk',
      issue_id: issue.id,
      provider: prefillSelector(providerById)(providerId).name,
    };

    const newFavoriteState = !issue.get('favourite');

    ProviderManager.favorite(user.id, providerId, newFavoriteState).then(() => {
      const eventName = newFavoriteState ? 'Add Favorite' : 'Remove favorite';
      Analytics.track(eventName, statsPayload);
      issue.set('favourite', newFavoriteState);
      setTsFavorite(newFavoriteState);
    });
  };

  return (
    <ImageWrapper className="v-cover cover-image" style={coverStyles}>
      <Link onClick={onClick} href={`/issue/${issue.get('provider').id}/${issue.id}`}>
        <Image
          animate={BrowserEnvironment.isDesktop()}
          src={issue.getCoverURL()}
          width={issue.getCoverWidth()}
          height={issue.getCoverHeight()}
          onLoad={onCoverLoad}
        />
      </Link>
      {user && isReady ? (
        <Button
          className={classNames('favourite', {
            'l-favourite': isFavorite,
          })}
          onClick={(e) => toggleFavourite(e)}
        />
      ) : null}
    </ImageWrapper>
  );
};

Cover.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  issue: object.isRequired,
  onClick: func,
  onLoad: func,
  coverStyles: shape({ width: number.isRequired, height: number.isRequired, overFlow: string })
    .isRequired,
};

Cover.defaultProps = {
  onClick: undefined,
  onLoad: undefined,
};

export default Cover;
