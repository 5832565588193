// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".imageWrapper-1XWru{box-shadow:0 4px 20px rgba(0,0,0,0.25),0 4px 10px rgba(0,0,0,0.08);border-radius:2px}\n", ""]);
// Exports
exports.locals = {
	"imageWrapper": "imageWrapper-1XWru"
};
module.exports = exports;
