import { gql } from 'apollo-boost';
import { GlobalFragments, ArticleFragments } from 'queries/fragments';

const RESULTS_PER_PAGE_DEFAULT = 24;

export const SEARCH_QUERY = gql`
  query searchPage(
    $query: String!
    $first: Int = ${RESULTS_PER_PAGE_DEFAULT}
    $after: String
    $credit: Boolean
  ) {
    searchPage(query: $query, first: $first, after: $after, credit: $credit) {
      edges {
        node {
          ... on SearchArticleCard {
            article {
              ...articleFragment
            }
          }
        }
      }
      pageInfo {
        ...pageInfoFragment
      }
    }
  }
  ${GlobalFragments.pageInfo}
  ${ArticleFragments.article}
`;
