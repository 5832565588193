import { useMutation } from '@apollo/react-hooks';
import Analytics from 'instances/analytics';
import { pipe, head, prop, path } from 'ramda';
import SyncActions from 'actions/SyncActions';
import { SYNC_ACTION_TYPE } from 'app-constants';
import { updateCache } from './cache';
import { ADD_ARTICLE_TO_READING_LIST, REMOVE_ARTICLE_FROM_READING_LIST } from './mutations';

const addArticleToReadingListSuccess = path(['addArticleToReadingList', 'success']);
const addArticleToReadingListErrors = path(['addArticleToReadingList', 'errors']);
const removeArticleFromReadingListSuccess = path(['removeArticleFromReadingList', 'success']);
const removeArticleFromReadingListErrors = path(['removeArticleFromReadingList', 'errors']);
const getMessage = pipe(head, prop('message'));
const getPath = pipe(head, prop('path'));

/**
 * Hook that allows you to add or remove an Article from the reading list
 * @param {bnlId} string bnlId of the article
 * @param {analytics} object key/value pair that will be included in the payload of the Analytics event
 */
const useReadingListMutations = ({ bnlId, analytics }) => {
  const onSuccess = ({ isPinned }) => {
    const eventName = isPinned ? 'Pin Item' : 'Unpin Item';

    Analytics.track(eventName, {
      ...analytics,
      item_id: bnlId,
    });

    SyncActions.addAction(SYNC_ACTION_TYPE.PIN_STATE_CHANGED_VIA_MUTATION, {
      itemId: bnlId,
      pinned: isPinned,
    });
  };

  const onError = ({ exception, errors }) => {
    window.ErrorLogger.captureException(exception, {
      bnlId,
      message: errors && getMessage(errors),
      path: errors && getPath(errors),
    });
  };

  const [add] = useMutation(ADD_ARTICLE_TO_READING_LIST, {
    variables: {
      bnlId,
    },
    update: (cache, { data }) => {
      const success = addArticleToReadingListSuccess(data);

      if (success) {
        updateCache({ cache, isPinned: true, bnlId });
      }
    },
    onCompleted: (data) => {
      const success = addArticleToReadingListSuccess(data);

      if (success) {
        onSuccess({ isPinned: true });
      } else {
        onError({
          exception: new Error('Failed to Pin Item'),
          errors: addArticleToReadingListErrors(data),
        });
      }
    },
  });

  const [remove] = useMutation(REMOVE_ARTICLE_FROM_READING_LIST, {
    variables: {
      bnlId,
    },
    update: (cache, { data }) => {
      const success = removeArticleFromReadingListSuccess(data);

      if (success) {
        updateCache({ cache, isPinned: false, bnlId });
      }
    },
    onCompleted: (data) => {
      const success = removeArticleFromReadingListSuccess(data);

      if (success) {
        onSuccess({ isPinned: false });
      } else {
        onError({
          exception: new Error('Failed to Unpin Item'),
          errors: removeArticleFromReadingListErrors(data),
        });
      }
    },
  });

  return {
    add,
    remove,
  };
};

export default useReadingListMutations;
