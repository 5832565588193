import React, { Children, memo } from 'react';
import styledModule from 'helpers/styledModule';
import { node } from 'prop-types';
import CSS from './styles.scss';
import GridItem from './GridItem';

const Grid = styledModule.div(CSS.grid);

const ArticleGrid = memo(({ children }) => (
  <Grid>
    {Children.map(
      children,
      // eslint-disable-next-line react/no-array-index-key
      (child, index) => child && <GridItem key={`grid-item-${index}`} item={child} />,
    )}
  </Grid>
));

ArticleGrid.propTypes = {
  children: node.isRequired,
};

export default ArticleGrid;
