// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-cqL_8{from{opacity:0}to{opacity:1}}.container-H4DdM{padding:60px 16px;animation:fadeIn-cqL_8 0.2s 0s ease-in forwards}.container-H4DdM a{text-decoration:none}.contentWrapper-3OAx3{padding:16px;max-width:400px;text-align:left;margin:0 auto;background-color:#fafafa;border-radius:4px;display:flex}.title-Nbhtq{padding-bottom:4px}.iconWrapper-3ZJOV{padding-left:8px;display:flex;justify-content:center;flex-direction:column}.icon-1jP_7{color:#5b5b5b}\n", ""]);
// Exports
exports.locals = {
	"container": "container-H4DdM",
	"fadeIn": "fadeIn-cqL_8",
	"contentWrapper": "contentWrapper-3OAx3",
	"title": "title-Nbhtq",
	"iconWrapper": "iconWrapper-3ZJOV",
	"icon": "icon-1jP_7"
};
module.exports = exports;
