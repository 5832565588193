import React from 'react';
import { string, func } from 'prop-types';
import Select from 'components/Select';
import { translate, getLocale, isBetaLocale, getIso6391 } from 'instances/i18n';
import countryInstance from 'instances/country';
import settings from 'config/settings';

const QueryLocaleField = ({ locale, onChange }) => {
  const getLanguageCountries = () =>
    settings.providerLocales
      .filter((providerLocale) => {
        const isCurrentLanguage = locale === getLocale();
        const isntBeta = !isBetaLocale(providerLocale) && !countryInstance.isBetaCountry();
        return isCurrentLanguage || isntBeta;
      })
      .reduce((countries, providerLocale) => {
        const country = getIso6391(providerLocale);
        if (!countries.includes(country)) {
          countries.push(country);
        }
        return countries;
      }, []);

  return (
    <Select name="locale" onChange={onChange}>
      <option key="all" value="all" selected={locale === 'all'}>
        {translate('search.dropdown.all_languages')}
      </option>
      {getLanguageCountries().map((country) => (
        <option key={country} value={country} selected={locale === country}>
          {translate(`languages.${country}`)}
        </option>
      ))}
    </Select>
  );
};

QueryLocaleField.propTypes = {
  locale: string.isRequired,
  onChange: func.isRequired,
};

export default QueryLocaleField;
